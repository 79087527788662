import { Carousel } from '@material-tailwind/react';
import CarouselArrow from '../icon/CarouselArrow';
import Banner from '../Banner';

function HeroCarousel() {
  return (
    <Carousel
      className="h-[480px] md:h-[500px] lg:h-[460px] xl:h-[590px] rounded-xl overflow-x-visible"
      prevArrow={({ handlePrev }) => (
        <CarouselArrow
          isLeft
          onClick={handlePrev}
          class="!absolute top-[45%] left-[0.5%] lg:left-[10%]"
        />
      )}
      nextArrow={({ handleNext }) => (
        <CarouselArrow
          onClick={handleNext}
          class="!absolute top-[45%] !right-[0.5%] lg:!right-[10%]"
        />
      )}
      navigation={() => <></>}
    >
      <Banner
        imageUrl="/home/hero-bg.webp"
        title="Bezpieczeństwo na drodze do celu"
        description="Poznaj misję firmy Binnar - lidera rozwiązań w zakresie urządzeń bezpieczeństwa ruchu drogowego."
        contactButtonTitle="Zapytaj o ofertę"
        whatsappButton
        contactButtonModal
      />
      <Banner
        imageUrl="/home/hero-2.png"
        title="Barierki drogowe na wyciągnięcie ręki"
        description="Sprawdź szeroką ofertę różnych rozwiązań w zakresie barier drogowych, mostowych i chodnikowych"
        contactButtonTitle="Zapytaj o ofertę"
        whatsappButton
        contactButtonModal
      />
    </Carousel>
  );
}

export default HeroCarousel;
