import createStyleForBackgroundImage from '@/helper/createStyleForBackgroundImage';
import { defaultStyles } from '@/helper/defaultStyles';
import OffersCarousel from './OffersCarousel';

function OffersSection() {
  return (
    <section
      className={`${defaultStyles.paddingBig} py-12`}
      style={createStyleForBackgroundImage('/home/offers-bg.webp')}
    >
      <OffersCarousel />
    </section>
  );
}

export default OffersSection;
