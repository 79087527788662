import { defaultStyles } from '@/helper/defaultStyles';
import AchievementsGroup from '../AchievementsGroup';
import TitleBlockWrapper from '../TitleBlockWrapper';

function MapUpperPart() {
  return (
    <TitleBlockWrapper
      title="Wybierz firmę świadomą twoich potrzeb"
      desc="Specjalizujemy się w projektach bezpieczeństwa drogowego dla budownictwa, oferując rozwiązania, które są dokładnie dostosowane do dynamicznie zmieniających się potrzeb branży. Wybierając nas, stawiasz na sprawdzone rozwiązania Binnar, które zapewniają bezpieczeństwo i zadowolenie. Dzięki naszej ofercie, firmy budowlane mogą skorzystać z najwyższej jakości usług i produktów, stawiając na pierwszym miejscu bezpieczeństwo drogowe."
      parentClass={defaultStyles.paddingBig}
    >
      <AchievementsGroup />
    </TitleBlockWrapper>
  );
}

export default MapUpperPart;
