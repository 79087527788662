function AchievementsCard(props: {
  title: string;
  desc: string;
  index: number;
}) {
  return (
    <div
      className="p-4 flex flex-col gap-4 text-center bg-whiteSubtle"
      key={props.index}
    >
      <div className="text-secondary font-bold text-[30px] lg:text-[50px]">
        {props.title}
      </div>
      <div className="font-light">{props.desc}</div>
    </div>
  );
}

export default AchievementsCard;
