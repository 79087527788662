import { defaultStyles } from '@/helper/defaultStyles';
import RealizationsList from './RealizationsList';

function RealizationsSection() {
  return (
    <section className={`${defaultStyles.paddingBig} py-8`}>
      <RealizationsList />
    </section>
  );
}

export default RealizationsSection;
