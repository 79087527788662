import MultiItemCarousel from '../MultiItemCarousel';
import TitleBlockWrapper from '../TitleBlockWrapper';

const data = [
  {
    imageUrl: '/references/Banimex.webp',
  },
  {
    imageUrl: '/references/Bitum.webp',
  },
  {
    imageUrl: '/references/Budimex.webp',
  },
  {
    imageUrl: '/references/Drogol.webp',
  },
  {
    imageUrl: '/references/Eurovia.webp',
  },
  {
    imageUrl: '/references/Mirbud.webp',
  },
  {
    imageUrl: '/references/Most.webp',
  },
  {
    imageUrl: '/references/PRIMOST.svg',
  },
  {
    imageUrl: '/references/PRUiM-Gliwice.webp',
  },
  {
    imageUrl: '/references/Sarinz.webp',
  },
  {
    imageUrl: '/references/Starbag.webp',
  },
];

function Slide(props: { imageUrl: string; index: number }) {
  return (
    <div
      key={props.index}
      className="p-4 flex flex-row justify-center items-center min-w-[240px] max-w-[240px] h-[64px]"
    >
      <img
        src={props.imageUrl}
        className="h-full"
        alt="reference image"
        loading="lazy"
      />
    </div>
  );
}

function ReferencesCarousel() {
  return (
    <TitleBlockWrapper
      title="Referencje naszych klientów"
      desc="Te referencje są potwierdzeniem naszej jakości, niezawodności oraz skuteczności w dostarczaniu rozwiązań zwiększających bezpieczeństwo. Zaufanie, które otrzymujemy od naszych partnerów biznesowych, jest dla nas najlepszą rekomendacją i motywacją do dalszego rozwijania naszej oferty."
      parentClass="!gap-12"
    >
      <MultiItemCarousel
        data={data}
        arrowsClass="!stroke-defaultDark"
        autoScroll
        elementWidth={272}
      >
        {data.map((item, index) => (
          <Slide {...item} index={index} />
        ))}
      </MultiItemCarousel>
    </TitleBlockWrapper>
  );
}

export default ReferencesCarousel;
