import createStyleForBackgroundImage from '@/helper/createStyleForBackgroundImage';
import CarouselArrow from '../icon/CarouselArrow';

function RealizationsCard(props: {
  title: string;
  desc?: string;
  index: number;
  id: number;
  imageUrl: string;
}) {
  return (
    <div
      className="flex flex-col justify-end gap-4 h-[400px]"
      style={createStyleForBackgroundImage(props.imageUrl)}
      key={props.index}
    >
      <div className="flex flex-row justify-between items-end backdrop-card-white p-4">
        <div className="flex flex-col gap-4">
          <div className="text-[25px] font-bold text-default">
            {props.title}
          </div>
          {props.desc && (
            <div className="text-default w-[70%]">{props.desc}</div>
          )}
        </div>
        <CarouselArrow
          onClick={() => window.location.assign('/realizations/' + props.id)}
        />
      </div>
    </div>
  );
}

export default RealizationsCard;
