import { defaultStyles } from '@/helper/defaultStyles';
import ReferencesCarousel from './ReferencesCarousel';

function ReferencesSection() {
  return (
    <section className={`${defaultStyles.paddingBig} py-12`}>
      <ReferencesCarousel />
    </section>
  );
}

export default ReferencesSection;
