import AchievementsCard from './AchievementsCard';

const data = [
  {
    title: '189',
    desc: 'Zrealizowanych projektów w 2023 roku',
  },
  {
    title: '500 000',
    desc: 'Przejechanych kilometrów',
  },
  {
    title: '4',
    desc: 'W tylu krajach obecnie działamy',
  },
];

function AchievementsGroup() {
  return (
    <div className="grid grid-cols-2 xl:grid-cols-3 gap-4">
      {data.map((item, index) => (
        <AchievementsCard {...item} index={index} />
      ))}
    </div>
  );
}

export default AchievementsGroup;
