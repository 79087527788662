import createStyleForBackgroundImage from '@/helper/createStyleForBackgroundImage';
import MultiItemCarousel from '../MultiItemCarousel';
import TitleBlockWrapper from '../TitleBlockWrapper';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Offer } from '@/types/type';
import { getAssetUrl } from '@/helper/getAssetUrl';

function Slide(props: {
  title: string;
  desc: string;
  imageUrl: string;
  id: number;
}) {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className="min-w-[230px] min-h-[400px] lg:min-w-[290px] lg:min-h-[400px] flex flex-col justify-end overflow-hidden transition-all duration-300 cursor-pointer"
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
      style={createStyleForBackgroundImage(props.imageUrl, {
        withDarkOverlay: false,
        backgroundPosition: 'top',
        backgroundSize: '130%',
        backgroundRepeat: 'no-repeat',
      })}
      onClick={() => window.location.assign('/offer/' + props.id)}
    >
      <div
        className={`${
          isHovered ? 'triangle-hover' : 'triangle'
        } transition-all duration-300 ease-in-out`}
      />
      <div
        className={`flex flex-col items-start justify-between bg-default p-4 transition-all duration-300 ease-in-out ${
          isHovered ? 'h-[70%] lg:h-[60%]' : 'h-[50%] lg:h-[40%]'
        }`}
      >
        <div className="flex flex-col gap-2">
          <div
            className={`text-[25px] font-bold transition-all duration-300 ease-in-out ${
              isHovered ? 'text-secondary' : 'text-defaultDark'
            }`}
          >
            {props.title}
          </div>
          <div className="text-defaultDark">{props.desc}</div>
        </div>
        <img
          src="/home/offer-arrow.svg"
          className={`w-[50px] h-[50px] justify-self-end self-end cursor-pointer transition-all duration-300 ${
            isHovered ? 'opacity-100' : 'opacity-0'
          }`}
          onClick={() => window.location.assign('/offer/' + props.id)}
          alt="arrow icon"
          loading="lazy"
        />
      </div>
    </div>
  );
}

function OffersCarousel() {
  const { data } = useQuery({
    queryKey: ['OFFERS_LIST'],
    queryFn: () =>
      axios
        .get('https://api.binnar.com.pl/offers/')
        .then((r) => r.data as Offer[]),
    staleTime: 10 * 60 * 1000,
  });

  return (
    <TitleBlockWrapper
      title="Zobacz naszą ofertę"
      desc="Nasza oferta obejmuje kompleksowe rozwiązania: optymalizację projektów, zaprojektowanie zaproponowanych rozwiązań, oraz kompleksową realizację inwestycji w zakresie urządzeń bezpieczeństwa ruchu drogowego. Wszystkie zaproponowane przez nas rozwiązania charakteryzują się wysoką jakością, trwałością i są dostosowane do spełnienia wszelkich obecnych norm. Zapraszamy do zapoznania się z pełną ofertą i otrzymania  rozwiązania idealnie dopasowanego do Twoich potrzeb."
      parentClass="!gap-12"
      titleClass="!text-default"
      descClass="!text-default"
    >
      <>
        {data && data.length != 0 && (
          <MultiItemCarousel data={data} class="gap-4">
            {data
              .sort((a, b) => a.order - b.order)
              .map((item) => (
                <Slide
                  title={item.name}
                  id={item.pk}
                  desc={item.productsDesc}
                  imageUrl={getAssetUrl(item.thumbnailUrl)}
                />
              ))}
          </MultiItemCarousel>
        )}
      </>
    </TitleBlockWrapper>
  );
}

export default OffersCarousel;
