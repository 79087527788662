import createStyleForBackgroundImage from '@/helper/createStyleForBackgroundImage';
import MapUpperPart from './MapUpperPart';

function MapSection() {
  return (
    <section className="flex flex-col py-8">
      <MapUpperPart />
      <div
        style={createStyleForBackgroundImage('/home/map.webp')}
        className="h-[300px] md:h-[400px] lg:h-[500px] xl:h-[600px] 2xl:h-[700px]"
      />
    </section>
  );
}

export default MapSection;
