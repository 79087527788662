import { FunctionComponent } from 'react';

import InfoBannerSection from '@/components/InfoBannerSection';
import MapSection from '@/components/home/MapSection';
import OffersSection from '@/components/home/OffersSection';
import RealizationsSection from '@/components/home/RealizationsSection';
import ReferencesSection from '@/components/home/ReferencesSection';
import SecuritySection from '@/components/home/SecuritySection';
import Head from 'next/head';
import HeroSection from '../components/home/HeroSection';

const Home: FunctionComponent = () => {
  return (
    <div className="w-full bg-white overflow-hidden tracking-[normal] text-left text-[1rem] text-gray-500">
      <Head>
        <title>Binnar</title>
        <meta name="description" content="Binnar Strona główna" />
      </Head>
      <HeroSection />
      <SecuritySection />
      <OffersSection />
      <InfoBannerSection />
      <MapSection />
      <RealizationsSection />
      <ReferencesSection />
    </div>
  );
};

export default Home;
