import { RealizationList } from '@/types/type';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import TitleBlockWrapper from '../TitleBlockWrapper';
import RealizationsCard from './RealizationsCard';
import { getAssetUrl } from '@/helper/getAssetUrl';

function RealizationsList() {
  const { data } = useQuery({
    queryKey: ['REALIZATIONS_LIST'],
    queryFn: () =>
      axios
        .get('https://api.binnar.com.pl/realizations/')
        .then((r) => r.data as RealizationList[]),
    staleTime: 10 * 60 * 1000,
  });

  return (
    <TitleBlockWrapper
      title="Ostatnie realizacje"
      desc="Zapraszamy do przejrzenia naszej kolekcji zakończonych projektów, która ilustruje naszą ekspertyzę i zdolność do dostarczania rozwiązań dopasowanych do specyficznych wymogów i oczekiwań naszych klientów. Prezentowane realizacje są dowodem efektywności naszych systemów bezpieczeństwa, podkreślając nasze zaangażowanie w podnoszeniu standardów ochrony na drogach."
    >
      <div className="grid grid-cols-1 md:grid-cols-2 justify-center items-center gap-4">
        {data && data.length != 0 && (
          <>
            {data
              .slice(0, 4)
              .sort((a, b) => a.order - b.order)
              .map((item, index) => (
                <RealizationsCard
                  imageUrl={getAssetUrl(item.backgroundUrl)}
                  id={item.pk}
                  title={item.name}
                  index={index}
                />
              ))}
          </>
        )}
      </div>
    </TitleBlockWrapper>
  );
}

export default RealizationsList;
