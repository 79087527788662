import { defaultStyles } from '@/helper/defaultStyles';
import TextWithBaner from '../TextWithBaner';

function SecuritySection() {
  return (
    <section className={`${defaultStyles.paddingBig} py-8`}>
      <TextWithBaner
        title="Dbamy o bezpieczeństwo na drodze"
        desc="Binnar proponuje szeroki zakres usług, który w głównej mierze opiera się na wsparciu i realizacji inwestycji drogowych i budowlanych. Od początku rozpoczęcia działalności naszej firmy obsłużyliśmy setki udanych realizacji i zbudowaliśmy trwałe relacje z naszymi partnerami biznesowymi."
        imageUrl="/home/security-img.webp"
      />
    </section>
  );
}

export default SecuritySection;
