import { FunctionComponent } from 'react';
import HeroCarousel from './HeroCarousel';

const HeroSection: FunctionComponent = () => {
  return (
    <section>
      <HeroCarousel />
    </section>
  );
};

export default HeroSection;
